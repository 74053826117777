import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo"
import PortfolioItem from "../../components/portfolio-item";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";

const UByMoenPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/u-by-moen/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/u-by-moen/01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/u-by-moen/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/u-by-moen/03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'U by Moen';
  const summary = `The U by Moen project marked the entry of the company into the Internet of Things space, worked
  remotely with peers collaborating across multiple timezones. The iOS application served as both a commissioning
  mechanism for the control and a control of its own. The Rails application had multiple responsibilities that ranged
  from supporting physical devices, to serving firmware updates, an admin dashboard and a repository for analytics.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <PortfolioItem
      name={title}
      year="2016"
      stack="Swift, Ruby"
      frameworks="Ruby on Rails"
      imageQuery={imageQuery}
      role={'iOS & Ruby Developer'}
      url="https://apps.apple.com/us/app/u-by-moen/id1171448279"
      summary={summary}
    />
  </Layout>
};

export default UByMoenPage
